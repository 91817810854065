import { Card, CardContent, Typography } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from "react";
import SEO from "../../../components/common/seo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    button: {
      margin: theme.spacing(1),
    },
    card: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    centered: {
      maxWidth: '640px',
      margin: 'auto'
    },
    action: {
      margin: theme.spacing(1),
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  }
  ))

const TutorialsComingSoonPage = () => {
  const classes = useStyles()

  return (
    <>
      <SEO title="Tutorials Coming Soon" />
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h1" component="h1">
              This tutorial is coming soon!
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Watch for updates on our <a className={classes.link} target="_blank" href="https://www.navability.io/blog"> Blog </a>.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default TutorialsComingSoonPage